import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Button from 'common/Button';
import Section from 'common/Section';
import BlockCarousel from 'components/BlockCarousel';
import RelatedArticleCard from 'components/RelatedArticleCard';
import PrevArrow from 'components/PDCarousel/components/prevArrow';
import NextArrow from 'components/PDCarousel/components/nextArrow';

import { UmbracoRelatedArticles } from '@shared/types/umbraco/compositions';
import { sortArticleByRelevant } from 'utils/sortByRelevant';
import { sortByOrderUrls } from 'utils/sortByOrderUrls';

import './RelatedArticles.scss';

const RelatedArticles: FC<UmbracoRelatedArticles.IData> = ({
  title,
  link,
  relatedArticles,
  selectedArticlesManual,
  selectedArticles,
  limit,
  relatedArticlesLinks = [''],
  isCarousel,
  waiSettings,
  color,
  customCards,
  alignCenter,
  selectedArticlesMode,
}) => {
  const articles = relatedArticles?.nodes?.length
    ? sortArticleByRelevant(relatedArticlesLinks, relatedArticles.nodes)
    : [];

  const selectedArticlesSort = selectedArticles?.length
    ? sortByOrderUrls(selectedArticlesManual.nodes, selectedArticles)
    : [];

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    customPaging: (i) => <Button ariaLabel={`Go to slide ${i + 1}`} variant="icon" />,
    prevArrow: <PrevArrow ariaLabel={waiSettings?.ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={waiSettings?.ariaNext} />,
    responsive: [
      {
        breakpoint: 1176,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const adjustedCustomCards =
    customCards?.map(({ properties }) => ({
      ...properties,
      link: properties?.link?.[0].url,
    })) || [];

  const cardsToRender = selectedArticlesMode
    ? selectedArticlesSort
    : articles
        .slice(0, limit || articles.length)
        .concat(adjustedCustomCards as UmbracoRelatedArticles.IArticleCard[]);

  return cardsToRender?.length ? (
    <Section name="related-articles" intro={title ? `<h2>${title}</h2>` : null} color={color}>
      <>
        {!isCarousel ? (
          <div
            className={classNames('related-articles-section__grid', {
              [`col-${cardsToRender.length}`]: alignCenter,
            })}
          >
            {cardsToRender.map((card) => (
              <RelatedArticleCard key={card.title} {...card} />
            ))}
          </div>
        ) : null}

        {isCarousel ? (
          <BlockCarousel settings={settings}>
            {cardsToRender.map((card) => (
              <RelatedArticleCard key={card.title} {...card} />
            ))}
          </BlockCarousel>
        ) : null}

        {link?.length ? (
          <div className="related-articles-section__more">
            <Button
              ariaLabel={link[0].properties.ariaLabel}
              to={link[0].properties.link?.[0]?.url}
              variant="primary-link"
              iconSuffix="chevron-right"
              className="related-articles-section__link"
            >
              {link[0].properties.link?.[0]?.name}
            </Button>
          </div>
        ) : null}
      </>
    </Section>
  ) : null;
};

export default RelatedArticles;

export const query = graphql`
  fragment FragmentRelatedArticles on TRelatedArticles {
    structure
    properties {
      title
      limit
      link {
        properties {
          ariaLabel
          link {
            name
            url
          }
        }
      }
      isCarousel
      color {
        label
      }
      customCards {
        properties {
          link {
            url
          }
          title
          tags {
            isCategory
            isLabel
            title
          }
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      alignCenter
      selectedArticlesMode
      selectedArticles {
        url
      }
    }
  }
`;
